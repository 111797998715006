<template>

    <div class="columns is-mobile pt-2 alerta mb-1" v-if="acces_error" >
        <div class="column mt-2 ml-1">
            <p style="color:#721c24;  " >Usuario ó Clave incorrecto!</p>
        </div>
        <div class="column has-text-right mr-1">
            <button  class="button is-rounded buttonClose" @click="acces_error=false">
                <span class="icon " >
                    <i class="far fa-times-circle"></i>
                </span>
            </button>
        </div>
    </div>

    <div class="columns is-mobile pt-2 alerta mb-1" v-if="habilit_error" >
        <div class="column mt-2 ml-1">
            <p style="color:#721c24;  " >Este usuario no está habilitado</p>
        </div>
        <div class="column has-text-right mr-1">
            <button  class="button is-rounded buttonClose" @click="habilit_error=false">
                <span class="icon " >
                    <i class="far fa-times-circle"></i>
                </span>
            </button>
        </div>
    </div>


    <div class="section container px-4" @keyup.enter="login">
        <div v-show="!isMobile">
            <img class="img" src="../assets/logotipo-PuWiC-login.png" alt="Img PuWiC" >
        </div>
         <div v-show="isMobile">
            <img class="img" src="../assets/logotipo-PuWiC-login.png" alt="Img PuWiC">
        </div>

        <div class="field  has-addons has-addons-centered">
            <div class="control ">
                <p class="button ">
                    <span class="icon is-small is-left" >
                        <i class="fas fa-user"></i>
                    </span>
                </p>
            </div>
            <div class="control">
                <input class="input " type="text" name="username" placeholder="Username" v-model="username">
            </div>
        </div>

        <div class="field has-addons has-addons-centered " >
            <div class="control  has-icons-left has-icons-right mobile">
                <input class="input pl-6" name="password" placeholder="Password" :type="passwordFieldType" v-model="password">
                    <span class="icon is-small is-left" >
                        <i  class="fas fa-lock"></i>
                    </span>
            </div>
            <div class="control">
                <button class="button " type="password" @click="switchVisibility">
                    <span class="icon is-small is-right" >
                        <i class="fas fa-eye"></i>
                    </span>
                </button>
            </div>
        </div>

        <div class="field " v-show="!ingresar">
            <div class="control has-text-centered ">
                <input type="submit"  @click="login" class="button has-text-weight-bold" id="button1" value="Ingresar">
            </div>
        </div>
        <div class="field " v-show="ingresar">
            <div class="control has-text-centered ">
                <button class="button is-loading" id="button1"></button>
            </div>
        </div>

    </div>

</template>


<script>
import {GraphQLClient, request as fetchGQL} from 'graphql-request';
import { inject, ref } from 'vue';
import { useRouter } from "vue-router";
import store from '@/store';


export default {
    setup(){

        const ingresar = ref(false);
        let username = ref('');
        let password = ref('');
        const acces_error = ref(false);
        const habilit_error = ref(false)
        const error_msg = '';
        const passwordFieldType = ref('password');
        const router = useRouter();
        const isMobile = inject('isMobile')

        const endpoint = store.state.url_backend

        const switchVisibility = () => {
            passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
        }

        const login = () => {

            ingresar.value = true
            fetchGQL(endpoint ,/* GraphQL */ `
            mutation($username: String!, $password: String!){
                login(input: {
                    username: $username
                    password: $password
                }) {
                    access_token,
                    user{
                        name
                    }
                }
            }`,
            {
                username: username.value,
                password: password.value
            }
            ).then((data) => {
                // console.log(data)
                if(data.access_token != ''){
                    // console.log('entro')
                    localStorage.setItem('user_token', data.login.access_token)
                    localStorage.setItem('username', data.login.user.name)
                    acces_error.value = false
                    me()

                    // router.push({name: 'Tablero'})

                }

            })
            .catch(error => {
                if (error.response) {
                    error = error.response.errors;
                    // console.log(error)
                } else {
                    error = 'Unknown error';
                }
                acces_error.value = true
                ingresar.value = false

                if(error.message == 'Authentication exception'){
                    error_msg.value = error.response.errors[0].extensions.reason
                }

            })
        }

        const me = () => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            query{
                me{
                    empresa{
                        habilitada
                    }
                }
            }`,
            {

            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)
                if (data.data.me.empresa.habilitada) {
                    localStorage.setItem('habilitado', data.data.me.empresa.habilitada)
                    router.push({name: 'Tablero'})
                } else {
                    habilit_error.value = true
                    ingresar.value = false
                }
            })
            .catch(error => {
                // console.log(error.response)
            })
        }

        return{
            ingresar,
            username,
            password,
            acces_error,
            error_msg,
            passwordFieldType,
            login,
            switchVisibility,
            isMobile,
            habilit_error
        }
    },

    created(){
        if (localStorage.getItem('user_token') ){
            this.$router.push({name: 'Home'})
        }
    }

}
</script>


<style scoped lang="scss">

@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";


$primary: yellow;
$button-color: white;
$button-background-color: #008575;

.input{
    width:18.1rem;
}

.button{
    color:  #123044 ;
}

#button1{
    background: $button-background-color;
    color: $button-color;
    width:20.5rem;
}

@media screen and (max-width: 370px) {
    #button1{
    width: 100%;
  }
}

.img{
    display: block;
    height: 8rem;
    margin: auto;
    width: auto;
}


.alerta{
    background-color: #f8d7da;
    border-color: #f5c6cb;
}


.buttonClose{
    background-color: rgba(255,255,255,0);
    color: #721c24;
    border-style: none;
}



</style>